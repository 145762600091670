import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../providers/i18n/KEYS';

export const ModalComponent = ({
  showModal,
  heading,
  text,
  handleClose,
  handleConfirm,
}: {
  showModal: boolean;
  heading: string;
  text: string;
  handleClose: () => void;
  handleConfirm: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{text}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-light" onClick={handleClose}>
            <span>{t(KEYS.no)}</span>
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            <span>{t(KEYS.yes)}</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalComponent;
