import React from 'react';
import ReactDOM from 'react-dom/client';
import i18n from 'i18next';
import i18next from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';

import { AuthenticationProvider } from './providers/auth/AuthenticationProvider';
import { resources } from './providers/i18n/resources';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

i18n.use(initReactI18next).init({
  debug: false,
  resources,
  fallbackLng: 'de',
  lng: 'de',
  defaultNS: 'translation',
  interpolation: {
    escapeValue: false,
  },
});

root.render(
  <React.StrictMode>
    <AuthenticationProvider>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </AuthenticationProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
