import { useEffect, useState } from 'react';
import { useClient } from '@services/hooks/useClient';
import { MealReservation } from '@type/Meal';

const useReservations = (): MealReservation[] | [] => {
  const client = useClient();
  const [reservations, setReservations] = useState<MealReservation[] | []>([]);

  useEffect(() => {
    const fetchMealReservations = async () => {
      try {
        const response = await client.get<MealReservation[]>(`/Meals/GetMeals`);
        setReservations(response.data);
      } catch (error) {
        console.error('Error fetching meal reservations:', error);
      }
    };

    fetchMealReservations();
  }, [client]);

  return reservations;
};

export { useReservations };
