import React, { useContext, useEffect, useState } from 'react';
import { MealTile } from '@components/MealTile/MealTile';
import { sortByName } from '@services/sort/sort';
import SelectWeek from '../../components/SelectWeek';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { WeekDayLabel } from '@components/WeekDayLabel/WeekDayLabel';
import DropdownComponent from '../../components/Dropdown/Dropdown';
import useComponentVisible from '../../services/hooks/useIsComponentVisible';
import { DataContext } from '@providers/data/DataProvider';
import { DayPlan, Meal } from '../../types/Meal';

export const CanteenPlan = () => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const { weekCanteenPlan, selectedDays, setSelectedDays, startDate, setStartDate, endDate, setEndDate } =
    useContext(DataContext);
  const [selectedMeal, setSelectedMeal] = useState<any | undefined>(undefined);
  const [selectedDate, setSelectedDate] = useState<string | undefined>(undefined);

  const onMealSelected = (mealId: number, meal: Meal) => {
    if (meal === selectedMeal) {
      return setSelectedMeal(undefined);
    }
    setSelectedMeal(meal);
  };

  useEffect(() => {
    weekCanteenPlan?.forEach((dayPlan: DayPlan) => {
      dayPlan?.dishes?.forEach((dayMeal: Meal) => {
        if (Number(dayMeal?.id) === selectedMeal?.id) {
          setSelectedDate(moment(dayPlan.planDate).format('YYYY-MM-DD'));
        }
      });
    });
  }, [selectedMeal]);

  const onMealSelectionReset = () => {
    setSelectedMeal(undefined);
    setSelectedDate(undefined);
  };

  return (
    <div>
      <div className={'flex'}>
        <div className={'flexColumn'}>
          <div className={'homeHeader'}>
            <div
              style={{
                flexBasis: 1,
                flexGrow: 1,
              }}
            >
              <Button
                style={{ fontFamily: 'ClanPro-Bold', marginLeft: 12 }}
                onClick={() => setIsComponentVisible(true)}
              >
                {moment(startDate).format('DD.MM.YYYY')} – {moment(endDate).format('DD.MM.YYYY')}
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                alignSelf: 'center',
                justifyContent: 'center',
              }}
            >
              <DropdownComponent
                initialPlaceholder={'Kantine'}
                items={['Kantine Winnenden', 'KVD']}
                onItemSelected={(item) => console.log(item)}
              />
            </div>
            <div
              style={{
                flexBasis: 1,
                flexGrow: 1,
              }}
            ></div>

            {isComponentVisible && (
              <div className={'calendar'} ref={ref}>
                <SelectWeek
                  selectedDays={selectedDays}
                  onSelectionChange={(data: any) => {
                    setSelectedDays(data);
                    const startDate = moment(data[1]).format('YYYY-MM-DD');
                    const endDate = moment(data[5]).format('YYYY-MM-DD');
                    setStartDate(startDate);
                    setEndDate(endDate);
                    setIsComponentVisible(false);
                  }}
                />
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            {weekCanteenPlan
              ?.filter((dayPlan: DayPlan) => {
                const planDate = moment(dayPlan.planDate).format('YYYY-MM-DD');
                const now = moment().format('YYYY-MM-DD');

                return now <= planDate;
              })
              .map((dayPlan: DayPlan, index: number) => {
                return (
                  <div key={index}>
                    <div style={{ width: 220, margin: 12 }}>
                      <WeekDayLabel day={new Date(dayPlan?.planDate).getDay()} />
                    </div>

                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <>
                        {sortByName(dayPlan?.dishes).map((meal: Meal, _index: number) => {
                          return (
                            <MealTile
                              meal={meal}
                              key={_index}
                              onMealSelected={onMealSelected}
                              selectable={index !== 4 && meal?.name?.includes('Gericht')}
                              checked={Number(selectedMeal?.id) === Number(meal.id)}
                              selectedDate={selectedDate}
                              isFriday={meal.name === 'Bedienen Sie sich nach Herzenslust vom Besten der Woche'}
                              planDate={dayPlan.planDate}
                            />
                          );
                        })}
                      </>
                      <br />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
