import Flag from 'react-world-flags';
import { useTranslation } from 'react-i18next';
import { languageSelectionStyle } from './LanguageSelection.style';
import { Country, Language } from '../../../config/locale';

export const LanguageSelection = (): JSX.Element => {
  const { i18n } = useTranslation();
  const onChangeLanguage = (event: any) => {
    const language = event.target.value;
    i18n.changeLanguage(language);
  };

  return (
    <div style={languageSelectionStyle.container} onChange={onChangeLanguage}>
      <div style={languageSelectionStyle.innerView}>
        <input
          type="radio"
          style={languageSelectionStyle.radioButton}
          value={Language.German}
          name="appLanguage"
          defaultChecked={i18n.language === Language.German}
        />
        <Flag code={Country.Germany} width={66} />
      </div>
      <div style={languageSelectionStyle.innerView}>
        <input
          type="radio"
          style={languageSelectionStyle.radioButton}
          value={Language.English}
          name="appLanguage"
          defaultChecked={i18n.language === Language.English}
        />
        <Flag code={Country.GreatBritain} width={66} />
      </div>
    </div>
  );
};
