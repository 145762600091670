export const KEYS = {
  order: 'order',
  reserve: 'reserve',
  profile: 'profile',
  send: 'send',
  collectedPointsDesc: 'collectedPointsDesc',
  selectedMeal: 'selectedMeal',
  reservations: 'reservations',
  points: 'points',
  menu: 'menu',
  personalZone: 'personalZone',
  price: 'price',
  timeOfDay: 'timeOfDay',
  takeAway: 'takeAway',
  takeawayNote: 'takeawayNote',
  eatOnSite: 'eatOnSite',
  quantity: 'quantity',
  monday: 'monday',
  tuesday: 'tuesday',
  wednesday: 'wednesday',
  thursday: 'thursday',
  friday: 'friday',
  yes: 'yes',
  no: 'no',
  deleteOrder: 'deleteOrder',
  deleteOrderDesc: 'deleteOrderDesc',
  fullPortion: 'fullPortion',
  halfPortion: 'halfPortion',
};
