import { Dropdown } from 'react-bootstrap';
import { useState } from 'react';

type DropdownComponentProps = {
  items: any[];
  initialPlaceholder: string;
  onItemSelected: (selectedItem: string) => void;
  value?: string;
};
const DropdownComponent = ({ value, items, initialPlaceholder, onItemSelected }: DropdownComponentProps) => {
  const [selectedItem, setSelectedItem] = useState(undefined);
  return (
    <Dropdown
      onSelect={(e: any) => {
        setSelectedItem(e);
        onItemSelected(e);
      }}
    >
      <Dropdown.Toggle variant={'primary'} id="dropdown-basic" value={selectedItem}>
        <span>{value ?? selectedItem ?? initialPlaceholder}</span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {items.map((item) => {
          return (
            <Dropdown.Item key={item} eventKey={item}>
              {item}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownComponent;
