export const languageSelectionStyle = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerView: {
    display: 'flex',
    marginLeft: 12,
  },
  radioButton: {
    width: 24,
    margin: 4,
  },
};
