import { useLocation, useNavigate } from 'react-router-dom';
import { headerStyle, headerStyleMobile } from './Header.style';
import { useIsMobile } from '../../services/hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../providers/i18n/KEYS';
import { LanguageSelection } from './LanguageSelection/LanguageSelection';
import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Icon } from '../Icon/Icon';
import { COLORS } from '../../style/Colors';

export const Header = () => {
  const { t } = useTranslation();
  const todayDate = new Date().toLocaleDateString();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();
  const isProfileActive = location.pathname === '/profile';
  const style = isMobile ? headerStyleMobile : headerStyle;

  const buttonStyleActive = {
    marginRight: 16,
    height: 42,
    backgroundColor: '#F1F1F1',
    borderWidth: 0,
    paddingBottom: 8,
  };
  const buttonStyleInActive = {
    marginRight: 16,
    height: 42,
    backgroundColor: 'white',
    borderWidth: 0,
    paddingBottom: 8,
  };

  return (
    <div className={'header'}>
      <div style={style.topContainer}>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <img src={require('../../assets/kaercher_logo.png')} style={{ width: isMobile ? 100 : 200 }} alt="logo" />
          <ButtonGroup aria-label="Basic example" style={{ marginLeft: 100 }}>
            <Button
              onClick={() => navigate('/home')}
              className={'button'}
              style={!isProfileActive ? buttonStyleActive : buttonStyleInActive}
            >
              {t(KEYS.menu).toUpperCase()}
            </Button>
            <Button
              onClick={() => navigate('/profile')}
              style={isProfileActive ? buttonStyleActive : buttonStyleInActive}
            >
              {t(KEYS.personalZone).toUpperCase()}
            </Button>
          </ButtonGroup>
        </div>

        <h3 className={'rightPosition'} style={style.languageSelection}>
          <Icon iconName={'Gear'} color={COLORS.black} style={{ marginRight: 8 }} />
          <Icon iconName={'Person'} color={COLORS.black} style={{ marginRight: 8 }} />
          <Icon iconName={'Bell'} color={COLORS.black} style={{ marginRight: 8 }} />
          <span style={{ marginRight: 32 }}>{todayDate}</span>
          <LanguageSelection />
        </h3>
      </div>
    </div>
  );
};
