import { useEffect, useState } from 'react';
import { WeekPlan } from '@type/Meal';
import { useClient } from '@services/hooks/useClient';

const useWeekPlan = ({ startDate, endDate }: { startDate: string; endDate: string }): WeekPlan | undefined => {
  const client = useClient();
  const [meals, setMeals] = useState<WeekPlan | undefined>(undefined);

  useEffect(() => {
    const fetchCanteenPlan = async () => {
      try {
        const response = await client.get<WeekPlan>(
          `Canteen/GetCanteenPlan?CanteenId=5&LanguageId=2&startDate=${startDate}&endDate=${endDate}`
        );
        setMeals(response.data);
      } catch (error) {
        console.error('Error fetching canteen plan:', error);
      }
    };

    fetchCanteenPlan();
  }, [client, startDate, endDate]);

  return meals;
};

export { useWeekPlan };
