export const sortByName = (data: any[]) => {
  return data.sort(function (a, b) {
    // here a , b is whole object, you can access its property
    //convert both to lowercase

    const x = a.name.includes('Gericht') ? a.name.substring(0, 9) : 'ZZZZZZZZZ';
    const y = b.name.includes('Gericht') ? b.name.substring(0, 9) : 'ZZZZZZZZZ';

    //compare the word which is comes first
    if (x > y) {
      return 1;
    }
    if (x < y) {
      return -1;
    }
    return 0;
  });
};
