import React, { useState } from 'react';
import { Divider } from 'src/components/Divider/Divider';
import { useTranslation } from 'react-i18next';
import { KEYS } from '@providers/i18n/KEYS';
import { Icon } from 'src/components/Icon/Icon';
import { Button } from 'react-bootstrap';
import ModalComponent from 'src/components/Modal/Modal';
import { MealEditOverlay } from '@components/Overlays/Edit/EditOverlay';
import { useClient } from '@services/hooks/useClient';
import { MealReservation } from '@type/Meal';

const mockImg =
  'https://media.kaercher.net/media/Locals%2fAKW%2fCanteen%2f__thumbnails%2fDummyWokstation.jpeg?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9tZWRpYS5rYWVyY2hlci5uZXQvbWVkaWEvTG9jYWxzJTJmQUtXJTJmQ2FudGVlbiUyZl9fdGh1bWJuYWlscyUyZkR1bW15V29rc3RhdGlvbi5qcGVnIiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzA0Mjk0MDI1fX19XX0_&Signature=ahKRL~0PWTZslDpWjTlDRUnyGAzKb6n606~Pv62URflLVh-xRxS6surGAmXVSOGoayXMiYz2OjCYM05wDC8Bl9YoSi0Hgap3ioj-~5qFCEVCRezS0dZFqAdq28tfURShW3R6ZU97-dJRaK5Pf2GjWBtUmVJ3Ijg9~RbM0qz0ZN1HDUfeBY9VsGemAbo4YdXTkAYkIbyBktGZF2lIofPd6P4WnV9-JDXiusJm-IahRWOynlz43JEKn3Qfm7J-IdF6u-ync-zHWIDOX5Rkduy3GPgB0MuHr5TeaMGQEe061Pm6A7miQ8u6ymjxS~-ptPi3kVCZe~E9LopdHsiNXrxqgQ__&Key-Pair-Id=APKAJLMX5A6F2CETGFWQ';

export const Reservation = ({ meal }: { meal: MealReservation }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showEditOverlay, setShowEditOverlay] = useState(false);
  const client = useClient();
  const onDeleteMealPress = () => {
    client
      .delete(`/Meals/DeleteMealID?id=${meal.id}`)
      .then((res) => {
        setShowModal(false);
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        marginRight: '15%',
        marginLeft: '15%',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        backgroundColor: 'white',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <div
          className={'flex'}
          key={meal.id}
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            backgroundColor: 'white',
          }}
        >
          <div>
            <img src={meal.imageUrl ?? mockImg} className={'mealImage'} />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              paddingTop: 12,
            }}
          >
            <span className={'reserved-meal-text bold'}>{meal.meal}</span>
            <span className={'reserved-meal-text '}>{`${meal.cantine} | ${
              meal.takeaway ? t(KEYS.takeAway) : t(KEYS.eatOnSite)
            }`}</span>
            <span className={'reserved-meal-text '}>{`${meal.date} | ${meal.time}`}</span>
            <div style={{ flexDirection: 'row', padding: 16, alignItems: 'center', justifyContent: 'center' }}>
              <Icon iconName={'Pencil'} size={16} />
              <Button
                variant="outline-light"
                style={{ maxHeight: 42, marginTop: 4 }}
                onClick={() => setShowEditOverlay(!showEditOverlay)}
              >
                {'Bearbeiten'}
              </Button>
            </div>
          </div>
        </div>
        <div style={{ flexDirection: 'row', padding: 16 }}>
          <Icon iconName={'Trash'} size={16} />
          <Button variant="outline-light" style={{ maxHeight: 42, marginTop: 4 }} onClick={() => setShowModal(true)}>
            {'Löschen'}
          </Button>
        </div>
      </div>
      <Divider />
      <ModalComponent
        heading={t(KEYS.deleteOrder)}
        text={t(KEYS.deleteOrderDesc)}
        handleConfirm={onDeleteMealPress}
        handleClose={() => setShowModal(false)}
        showModal={showModal}
      />
      {showEditOverlay && (
        <MealEditOverlay
          onClose={() => setShowEditOverlay(!showEditOverlay)}
          imageUrl={meal.imageUrl ?? mockImg}
          mealName={meal.meal}
          mealPrice={meal.price?.toString()}
          mealDate={meal.date}
          mealTime={meal.time}
        />
      )}
    </div>
  );
};
