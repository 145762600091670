import { MealReservation } from '@type/Meal';
import { Reservation } from '@components/PersonalSpace/Reservation/Reservation';
import { useContext } from 'react';
import { DataContext } from '@providers/data/DataProvider';

export const ReservationList = () => {
  const { reservations } = useContext(DataContext);

  return reservations ? (
    <>
      {reservations.map((meal: MealReservation, index: number) => {
        return <Reservation meal={meal} key={index} />;
      })}
    </>
  ) : null;
};
