import { COLORS } from '../../style/Colors';
import { FONTSIZE } from '../../style/Fonts';

export const headerStyle = {
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 50,
    paddingLeft: 50,
    paddingTop: 30,
  },
  bottomContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    backgroundColor: COLORS.yellow,
    padding: 12,
    marginBottom: 34,
  },
  tabs: {
    display: 'flex',
    width: '70%',
  },
  text: {
    fontFamily: 'ClanPro-Bold',
    fontSize: FONTSIZE.headline1400,
  },
  languageSelection: {
    display: 'flex',
    alignItems: 'center',
  },
};

export const headerStyleMobile = {
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 16,
    paddingLeft: 16,
    paddingTop: 10,
  },
  bottomContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
  },
  tabs: {
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: COLORS.grey50,
    margin: 16,
    width: '100%',
    alignSelf: 'center',
  },
  text: {
    fontFamily: 'ClanPro-Bold',
    fontSize: FONTSIZE.headline1400,
  },
  languageSelection: {
    display: 'flex',
    alignItems: 'center',
  },
};
