import React, { useEffect, useRef, useState } from 'react';
import styles from 'src/components/Overlays/Reservation/ReservationOverlay.style.module.scss';
import Popover from 'react-bootstrap/Popover';
import { Button, CloseButton, Form, OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { KEYS } from '@providers/i18n/KEYS';
import { COLORS } from '@style/Colors';
import { useClient } from '@services/hooks/useClient';
import DropdownComponent from 'src/components/Dropdown/Dropdown';
import { formatMealPrice } from '@services/helpers/format';
import { Meal, MealType } from '@type/Meal';

export const TakeAwayExtraCharge = 1.2;

interface MealReservationOverlayProps {
  meal: Meal;
  onClose: () => void;
  imageUrl: string;
  mealName: string;
  mealPrice: string;
  selectedDate?: string;
}

export const MealReservationOverlay: React.FC<MealReservationOverlayProps> = ({
  meal,
  onClose,
  imageUrl,
  mealName,
  mealPrice,
  selectedDate,
}) => {
  const { t } = useTranslation();
  const client = useClient();
  const [quantity, setQuantity] = useState(1);
  const [takeAway, setTakeAway] = useState(false);
  const [halfPortion, setHalfPortion] = useState(false);
  const [success, setSuccess] = useState(false);
  const overlayContentRef = useRef<HTMLDivElement>(null);
  const [selectedTime, setSelectedTime] = useState<string | undefined>(undefined);
  const label = mealName.includes('Gericht') ? mealName.substring(0, 9) : 'Kaltgericht';
  const reservationTime = ['11:30 - 12:00', '12:00 - 12:30', '12:30 - 13:00', '13:00 - 13:15'];

  const price = formatMealPrice((Number(mealPrice) + (takeAway ? TakeAwayExtraCharge : 0)) * quantity);

  const handleQuantityChange = (amount: number) => {
    setQuantity(Math.max(1, quantity + amount));
  };

  const handleTakeAwayClick = (e: any) => {
    if (e.target.value === 'takeaway') {
      setTakeAway(true);
      setHalfPortion(false);
    } else {
      setTakeAway(false);
    }
  };

  const handleHalfPortionClick = (e: any) => {
    if (e.target.value === 'full_portion') {
      setHalfPortion(false);
    } else {
      setHalfPortion(true);
    }
  };

  const handleSubmit = () => {
    if (selectedTime || takeAway) {
      if (quantity <= 1) {
        onMealPost(quantity, takeAway, halfPortion, imageUrl, selectedTime);
      } else {
        onMultipleMealsPost(quantity, takeAway, halfPortion, imageUrl, selectedTime);
      }
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (overlayContentRef.current && !overlayContentRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const onMealPost = (quantity: number, takeaway: boolean, halfPortion: boolean, imageUrl: string, time?: string) => {
    client
      .post(`/Meals/setMeal`, {
        date: selectedDate,
        time: time,
        meal: mealName.substring(0, 9),
        cantine: 'Cantine Winnenden',
        imageUrl,
        takeaway,
        half: halfPortion,
      })
      .then((response) => {
        console.log(response);
        setSuccess(true);
      })
      .catch((err) => console.log(err));
  };

  const onMultipleMealsPost = (
    quantity: number,
    takeaway: boolean,
    halfPortion: boolean,
    imageUrl: string,
    time?: string
  ) => {
    const postData = {
      date: selectedDate,
      time: time,
      meal: mealName.substring(0, 9),
      cantine: 'Cantine Winnenden',
      takeaway,
      imageUrl,
      half: halfPortion,
      amount: quantity,
    };
    client
      .post(`/Meals/SetMeals`, postData)
      .then((response) => {
        console.log(response);
        setSuccess(true);
      })
      .catch((err) => console.log(err));
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Fehler: Fehlende Informationen</Popover.Header>
      <Popover.Body>
        Bitte wählen Sie zuerst eine Uhrzeit, danach kann die Bestellung abgeschlossen werden.
      </Popover.Body>
    </Popover>
  );

  return (
    <div className={styles.overlay}>
      <div className={styles.overlayContent} id="overlayContent" ref={overlayContentRef}>
        <div className={styles.topClose}>
          {success ? <h5>{'Du hast folgenden Gericht bestellt'}</h5> : <div></div>}
          <CloseButton onClick={onClose} color={COLORS.black} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <img src={imageUrl} alt={mealName} className={styles.mealImage} />
          <h5 className={styles.mealName}>{mealName}</h5>
        </div>
        {success ? (
          <h6 style={{ marginTop: 16, fontWeight: 'bold' }}>{takeAway ? 'Take Away' : 'Vor Ort essen'}</h6>
        ) : (
          <Form>
            <div key={`default-${'radio'}`} className={styles.radioButtons}>
              <Form.Check
                value={'inplace'}
                type="radio"
                id="inplace_radio_button"
                label={t(KEYS.eatOnSite)}
                checked={!takeAway}
                className={styles.radioButtonInput}
                onChange={handleTakeAwayClick}
              />
              <Form.Check
                value={'takeaway'}
                type="radio"
                id="takeaway_radio_button"
                label={t(KEYS.takeAway)}
                checked={takeAway}
                className={styles.radioButtonInput}
                onChange={handleTakeAwayClick}
              />
            </div>
          </Form>
        )}
        <div className={styles.options}>
          <div className={styles.quantity}>
            <h5>
              <strong>{`${t(KEYS.quantity)}:`}</strong>
            </h5>
            {success ? (
              <h6>{quantity}</h6>
            ) : (
              <div className={styles.numberSelect}>
                <button className={styles.quantityBotton} onClick={() => handleQuantityChange(-1)}>
                  -
                </button>
                <span className={styles.quantityValue}>{quantity}</span>
                <button className={styles.quantityBotton} onClick={() => handleQuantityChange(1)}>
                  +
                </button>
              </div>
            )}
          </div>
          {success ? (
            <h6
              style={{
                marginTop: 16,
                fontWeight: 'bold',
              }}
            >
              {halfPortion ? 'Halbe Portion' : 'Ganze Portion'}
            </h6>
          ) : (
            <Form>
              <div key={`default-${'radio'}`} className={styles.radioButtons}>
                <Form.Check
                  value={'full_portion'}
                  type="radio"
                  id="full_portion_radio_button"
                  label={t(KEYS.fullPortion)}
                  checked={!halfPortion}
                  className={styles.radioButtonInput}
                  onChange={handleHalfPortionClick}
                />
                {!takeAway && meal.dishCategory.name !== MealType.ColdMeal && (
                  <Form.Check
                    value={'half_portion'}
                    type="radio"
                    id="half_portion_radio_button"
                    label={t(KEYS.halfPortion)}
                    checked={halfPortion}
                    className={styles.radioButtonInput}
                    onChange={handleHalfPortionClick}
                  />
                )}
              </div>
            </Form>
          )}
          {takeAway && <h6 style={{ marginTop: 8 }}>{t(KEYS.takeawayNote)}</h6>}
          {takeAway ? null : success ? (
            <div style={{ marginTop: 16 }}>
              <h6 style={{ fontWeight: 'bold' }}>{`${selectedDate} | ${selectedTime}`}</h6>
            </div>
          ) : (
            <div className={styles.times}>
              <h5>
                <strong>{`${t(KEYS.timeOfDay)}:`}</strong>
              </h5>
              <div className={styles.timeSelect}>
                <DropdownComponent
                  value={selectedTime}
                  items={reservationTime}
                  initialPlaceholder={t(KEYS.timeOfDay)}
                  onItemSelected={(_selectedTime) => setSelectedTime(_selectedTime)}
                />
              </div>
            </div>
          )}
          {/*   <div className={styles.specialRequests}>
            <h5>
              <strong>Zusatzoptionen:</strong>
            </h5>
            <div className={styles.specialSelect}>
              <button className={cx(styles.specialButton, { [styles.selectedOption]: takeAway })}>Take Away</button>
              <button
                onClick={handleHalfPortionClick}
                className={cx(styles.specialButton, { [styles.selectedOption]: halfPortion })}
              >
                Halbe Portion
              </button>
            </div>
          </div>*/}
          {!selectedTime && !takeAway ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <OverlayTrigger overlay={popover} placement={'top'} delay={{ show: 150, hide: 600 }}>
                <div>
                  <Button
                    onClick={handleSubmit}
                    style={
                      !selectedTime && !takeAway
                        ? {
                            backgroundColor: COLORS.grey50,
                            borderColor: COLORS.grey50,
                            opacity: 0.5,
                          }
                        : undefined
                    }
                    disabled={!selectedTime && !takeAway}
                  >
                    {t(KEYS.reserve)}
                  </Button>
                </div>
              </OverlayTrigger>
              <h5>{`${price} EUR`}</h5>
            </div>
          ) : success ? (
            <div>
              <h5>{`${t(KEYS.price)}: ${price} EUR`}</h5>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Button onClick={handleSubmit} disabled={!selectedTime && !takeAway}>
                {t(KEYS.reserve)}
              </Button>
              <h5>{`${price} EUR`}</h5>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
