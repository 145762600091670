import { COLORS } from '@style/Colors';

export const Divider = () => {
  return (
    <div
      style={{
        height: 1,
        backgroundColor: COLORS.grey100,
        width: '100%',
      }}
    ></div>
  );
};
