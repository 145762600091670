import { useEffect, useState } from 'react';
import { useClient } from 'src/services/hooks/useClient';

const usePoints = (): number => {
  const client = useClient();
  const [userPoints, setUserPoints] = useState<number>(0);

  useEffect(() => {
    const fetchUserPoints = async () => {
      try {
        const response = await client.get<number>(`/Points/GetPoints`);
        setUserPoints(response.data);
      } catch (error) {
        console.error('Error fetching user points:', error);
      }
    };

    fetchUserPoints();
  }, [client]);

  return userPoints;
};

export { usePoints };
