import { KEYS } from '@providers/i18n/KEYS';
import { useContext } from 'react';
import { DataContext } from '@providers/data/DataProvider';
import { useTranslation } from 'react-i18next';
import './CollectedPoints.style.scss';

const Donuts = ({ number }: { number: number }) => {
  return (
    <div style={{ marginBottom: 12 }}>
      {[...Array(number)].map((_, index) => (
        <img key={index} src={require('@assests/img/Donut.svg').default} style={{ margin: 4 }} />
      ))}
    </div>
  );
};

export const CollectedPoints = () => {
  const { t } = useTranslation();
  const { collectedPoints } = useContext(DataContext);

  return (
    <div className={'donuts'}>
      <Donuts number={5} />
      <h3 className={'bold'}>
        {t(KEYS.collectedPointsDesc, {
          points: collectedPoints,
        })}
      </h3>
    </div>
  );
};
