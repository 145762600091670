export const COLORS = {
  // general
  white: '#FFFFFF',
  black: '#000000',
  // primary
  yellow: '#FFED00',

  // secondary
  petrol500: '#238C96',
  petrol400: '#3998A1',
  petrol300: '#65AFB6',
  petrol200: '#91C6CB',
  petrol100: '#BDDDE0',
  petrol50: '#E9F4F5',

  // tertiary
  red500: '#B61A2D',
  red400: '#BD3142',
  red300: '#CC5F6C',
  red200: '#DB8D96',
  red100: '#E9BAC0',
  red50: '#F8E8EA',

  // quaternary
  olive500: '#4B8969',
  olive400: '#5D9578',
  olive300: '#81AC96',
  olive200: '#A5C4B4',
  olive100: '#C9DCD2',
  olive50: '#EDF3F0',

  // quinary
  sand500: '#B1B0A1',
  sand400: '#B9B8AA',
  sand300: '#C8C8BD',
  sand200: '#D8D8D0',
  sand100: '#E8E7E3',
  sand50: '#F7F7F6',

  // success
  success500: '#4AAD40',
  success400: '#5AB351',
  success300: '#80C579',
  success200: '#A4D69F',
  success100: '#C9E6C6',
  success50: '#EDF7EC',

  // info
  info500: '#007BFF',
  info400: '#1786FC',
  info300: '#4CA3FF',
  info200: '#80BDFF',
  info100: '#B3D7FF',
  info50: '#E5F2FF',

  // error
  error500: '#E2021A',
  error400: '#E2192E',
  error300: '#EB4E5F',
  error200: '#F1808C',
  error100: '#F6B3BA',
  error50: '#FCE6E8',

  // warning
  warning500: '#F5802A',
  warning400: '#F48A3D',
  warning300: '#F8A66A',
  warning200: '#FABF95',
  warning100: '#FCD9BF',
  warning50: '#FEF2EA',

  // neutral
  grey500: '#6E6E6E',
  grey400: '#7D7D7D',
  grey300: '#9A9A9A',
  grey200: '#B7B7B7',
  grey100: '#D3D3D3',
  grey50: '#F0F0F0',

  // special
  professionalEco100: '#109EC8',
  professionalEco200: '#C4C3C3',
  customerEco300: '#4AAD40',
};
