import React, { createContext, ReactNode, useMemo, useState } from 'react';
import moment from 'moment/moment';
import { getWeekDays, getWeekRange } from '../../components/SelectWeek';
import { useWeekPlan } from '@services/hooks/useWeekPlan';
import { useReservations } from '@services/hooks/useReservations';
import { MealReservation, WeekPlan } from '@type/Meal';
import { usePoints } from '@services/hooks/usePoints';

type ContextProviderProps = {
  children: ReactNode;
};

type AppContextData = {
  weekCanteenPlan: WeekPlan | undefined;
  startDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  endDate: string;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  selectedDays: Date;
  setSelectedDays: React.Dispatch<React.SetStateAction<Date>>;
  collectedPoints?: number;
  reservations?: MealReservation[];
};

export const DataContext = createContext<AppContextData>({
  weekCanteenPlan: undefined,
  startDate: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setStartDate: () => {},

  endDate: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setEndDate: () => {},

  selectedDays: new Date(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedDays: () => {},
});

const calculateInitialDates = () => {
  const initialStartDate = moment(getWeekDays(getWeekRange(moment()).from)[1]).format('YYYY-MM-DD');
  const initialEndDate = moment(getWeekDays(getWeekRange(moment()).from)[5]).format('YYYY-MM-DD');
  return { initialStartDate, initialEndDate };
};

const DataProvider = ({ children }: ContextProviderProps): JSX.Element => {
  const { initialStartDate, initialEndDate } = useMemo(calculateInitialDates, []);

  const [selectedDays, setSelectedDays] = useState<Date>(getWeekRange(moment()).from);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const weekCanteenPlan: WeekPlan | undefined = useWeekPlan({ startDate, endDate });
  console.log(weekCanteenPlan);
  const userPoints: number = usePoints();
  const reservations: MealReservation[] = useReservations();

  const value: AppContextData = {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedDays,
    setSelectedDays,
    weekCanteenPlan,
    collectedPoints: userPoints,
    reservations,
  };
  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export { DataProvider };
