import React, { useContext, useState } from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import { mealTileStyle } from './MealTile.style';
import { Button, OverlayTrigger } from 'react-bootstrap';
import './style.scss';
import { Label } from '@components/MealTile/Label/Label';
import { Description } from '@components/MealTile/Description/Description';
import { KEYS } from '@providers/i18n/KEYS';
import { useTranslation } from 'react-i18next';
import { formatMealPrice } from '@services/helpers/format';
import Popover from 'react-bootstrap/Popover';
import { Divider } from '@components/Divider/Divider';
import { Ingredients } from '@components/MealTile/Ingredients/Ingredients';
import moment from 'moment';
import { Meal, MealIngredient } from '@type/Meal';
import { COLORS } from '@style/Colors';
import { MealReservationOverlay } from '@components/Overlays/Reservation/ReservationOverlay';

export const MealTile = ({
  meal,
  onMealSelected,
  checked,
  selectable,
  selectedDate,
  planDate,
  isFriday,
}: {
  meal: Meal;
  onMealSelected: (mealId: number, dish: Meal) => void;
  checked: boolean;
  selectable: boolean;
  isFriday: boolean;
  planDate: string;
  selectedDate?: string;
}): JSX.Element => {
  const { t } = useTranslation();
  const { token } = useContext<IAuthContext>(AuthContext);
  const [overlayVisible, setOverlayVisible] = useState(false);

  const onMealValueSelected = () => {
    onMealSelected(meal.id, meal);
  };

  let ingredientsPopoverText = '';
  meal.ingredients.map((item: MealIngredient) => {
    if (item?.translation?.name) {
      ingredientsPopoverText = `${ingredientsPopoverText}${item.translation.name}, `;
    }
  });
  const popover = (
    <Popover id="popover-ingredients">
      <Popover.Body>
        <h5>{ingredientsPopoverText.replace(/,\s*$/, '')}</h5>
      </Popover.Body>
    </Popover>
  );

  const now = moment().format('YYYY-MM-DD HH:mm');
  const maxValidTime = moment(planDate).format('YYYY-MM-DD 09:00');
  const isReservationPossible = now < maxValidTime;

  return token ? (
    <div style={mealTileStyle.container} className={'mealTile'}>
      <Label meal={meal} isFriday={isFriday} />
      <div style={mealTileStyle.contentContainer}>
        <img src={meal?.thumbnailUrl} className={'mealImage'} />
        <Description text={meal?.name} />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: 12,
        }}
      >
        <div>
          {selectable && (
            <Button
              style={
                !isReservationPossible
                  ? {
                      backgroundColor: COLORS.grey50,
                      borderColor: COLORS.grey50,
                      opacity: 0.5,
                    }
                  : undefined
              }
              disabled={!isReservationPossible}
              onClick={() => {
                console.log('Bestellung ' + meal.name);
                onMealValueSelected();
                setOverlayVisible(!overlayVisible);
              }}
            >
              {t(KEYS.reserve)}
            </Button>
          )}
        </div>

        <h5 className={'label'} style={{ marginTop: 6 }}>
          {formatMealPrice(meal.price)} EUR
        </h5>
        {overlayVisible && (
          <MealReservationOverlay
            meal={meal}
            onClose={() => setOverlayVisible(false)}
            imageUrl={meal.thumbnailUrl}
            mealName={meal.name}
            mealPrice={formatMealPrice(meal.price)}
            selectedDate={selectedDate}
          />
        )}
      </div>
      <Divider />
      <OverlayTrigger overlay={popover} placement={'top'} delay={{ show: 150, hide: 600 }}>
        <div>
          <Ingredients meal={meal} />
        </div>
      </OverlayTrigger>
    </div>
  ) : (
    <></>
  );
};
