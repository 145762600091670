export const GUIDELINES = {
  /* BORDER-RADIUS */
  borderRadius0: 0,
  borderRadius4: 4,
  borderRadius8: 8,
  borderRadius12: 12,
  borderRadius16: 16,
  borderRadius24: 24,
  borderRadius32: 32,

  /* SPACING */
  spacing4: 4,
  spacing8: 8,
  spacing12: 12,
  spacing16: 16,
  spacing23: 23,
  spacing24: 24,
  spacing32: 32,
  spacing40: 40,
  spacing48: 48,
  spacing64: 64,
  spacing80: 80,
  spacing128: 128,
} as const;
