import React from 'react';
import moment from 'moment';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import PropTypes, { oneOfType } from 'prop-types';
import './styles.css';

export function getWeekDays(weekStart) {
  const days = [weekStart];
  for (let i = 1; i < 7; i += 1) {
    days.push(moment(weekStart).add(i, 'days').toDate());
  }
  return days;
}

export function getWeekRange(date) {
  return {
    from: moment(date).startOf('week').toDate(),
    to: moment(date).endOf('week').toDate(),
  };
}

// TODO: Convert this component to TS
class SelectWeek extends React.Component {
  state = {
    hoverRange: undefined,
    selectedDays: getWeekDays(getWeekRange(this.props.selectedDays[0]).from),
  };

  handleDayChange = (date) => {
    this.setState(
      {
        selectedDays: getWeekDays(getWeekRange(date).from),
      },
      () => this.props.onSelectionChange(getWeekDays(getWeekRange(date).from))
    );
  };

  handleDayEnter = (date) => {
    this.setState({
      hoverRange: getWeekRange(date),
    });
  };

  handleDayLeave = () => {
    this.setState({
      hoverRange: undefined,
    });
  };

  handleWeekClick = (weekNumber, days, e) => {
    this.setState({
      selectedDays: days,
    });
  };

  render() {
    const { hoverRange, selectedDays } = this.state;

    const daysAreSelected = selectedDays.length > 0;

    const modifiers = {
      hoverRange,
      selectedRange: daysAreSelected && {
        from: selectedDays[1],
        to: selectedDays[5],
      },
      hoverRangeStart: hoverRange && hoverRange.from,
      hoverRangeEnd: hoverRange && hoverRange.to,
      selectedRangeStart: daysAreSelected && selectedDays[1],
      selectedRangeEnd: daysAreSelected && selectedDays[5],
    };

    return (
      <div>
        <DayPicker
          disabledDays={[{ daysOfWeek: [0, 6] }]}
          selectedDays={selectedDays}
          showWeekNumbers
          showOutsideDays
          modifiers={modifiers}
          onDayClick={this.handleDayChange}
          onDayMouseEnter={this.handleDayEnter}
          onDayMouseLeave={this.handleDayLeave}
          onWeekClick={this.handleWeekClick}
        />
        {selectedDays.length === 7 && (
          <div>
            {moment(selectedDays[0]).format('LL')} – {moment(selectedDays[6]).format('LL')}
          </div>
        )}
      </div>
    );
  }
}

SelectWeek.propTypes = {
  selectedDays: oneOfType([PropTypes.array, PropTypes.object]),
  onSelectionChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  disabledBefore: PropTypes.string,
};

export default SelectWeek;
