export type MealTranslation = {
  ingredientId: number;
  ingredientType: number;
  id: number;
  name: string;
  languageId: number;
};

export type MealIngredient = {
  translation: MealTranslation | null;
  id: number;
  isFitAndHealthy: boolean;
  name: string;
  ingredientType: number;
  index: number | null;
  iconName: string | null;
  iconUrl: string | null;
  thumbnailIconUrl: string | null;
};

export type DishCategory = {
  translation: MealTranslation | null;
  id: string;
  name: MealType;
};

export type Meal = {
  cantine: string;
  date: string;
  dish: any | null;
  dishCategory: DishCategory;
  fileLocation: string;
  id: number;
  imageFielId: number;
  imageFileUrl: string;
  imageUrl: string;
  ingredients: MealIngredient[];
  name: string;
  price: number;
  takeaway: boolean;
  thumbnailUrl: string;
  time: string;
};

export type DayPlan = {
  currency: string;
  description: string;
  dishCategories: any;
  dishes: Meal[];
  id: number;
  isClosed: boolean;
  isDeactivate: boolean;
  isShowCategory: boolean;
  planDate: string;
};

export type WeekPlan = DayPlan[];

export type MealReservation = {
  id: string;
  user: string;
  cantine: string;
  imageUrl: string;
  date: string;
  half: boolean;
  meal: string;
  price: string;
  received: boolean;
  takeaway: boolean;
  time: string;
  transactionId: string;
};

export enum MealType {
  MainMeal = 'Hauptgericht',
  ColdMeal = 'Kaltgericht',
  Soup = 'Suppe',
}
