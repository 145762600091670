import React from 'react';
import styles from 'src/components/MealTile/Label/Label.style.module.scss';
import { Meal, MealType } from '@type/Meal';

export const Label = ({ meal, isFriday }: { meal: Meal; isFriday: boolean }) => {
  let label = '';

  switch (meal.dishCategory.name) {
    case MealType.MainMeal:
      label = meal.name.substring(0, 9);
      break;
    case MealType.ColdMeal:
      label = `${meal.name.substring(0, 9)} (Kaltgericht)`;
      break;
    case MealType.Soup:
      label = 'Suppe';
      break;
  }

  return (
    <div className={styles.container}>
      <span className={styles.label}>{`${isFriday ? 'All you can eat' : label}`}</span>
    </div>
  );
};
