import { GUIDELINES } from '../../style/Guidelines';
import { COLORS } from '../../style/Colors';

interface StyleSheet {
  [key: string]: React.CSSProperties;
}

export const mealTileStyle: StyleSheet = {
  container: {
    width: 220,
    height: 380,
    marginBottom: GUIDELINES.spacing16,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: COLORS.white,
  },
  contentContainer: {
    flex: 1,
  },
  text: {
    textAlign: 'center',
    padding: 3,
  },
  mealIngredient: {
    width: 24,
    height: 24,
    margin: 8,
  },
};
