import React, { useEffect, useRef, useState } from 'react';
import styles from 'src/components/Overlays/Edit/EditOverlay.style.module.scss';
import Popover from 'react-bootstrap/Popover';
import { CloseButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { KEYS } from '@providers/i18n/KEYS';
import { COLORS } from '@style/Colors';
import { useClient } from '@services/hooks/useClient';
import DropdownComponent from 'src/components/Dropdown/Dropdown';

export const TakeAwayExtraCharge = 1.2;

interface MealEditOverlayProps {
  onClose: () => void;
  imageUrl: string;
  mealName: string;
  mealPrice: string;
  mealDate: string;
  mealTime: string;
}

export const MealEditOverlay: React.FC<MealEditOverlayProps> = ({
  onClose,
  imageUrl,
  mealName,
  mealPrice,
  mealDate,
  mealTime,
}) => {
  const { t } = useTranslation();
  const client = useClient();

  const [success, setSuccess] = useState(false);
  const overlayContentRef = useRef<HTMLDivElement>(null);
  const [selectedTime, setSelectedTime] = useState<string | undefined>(undefined);

  const reservationTime = ['11:30 - 12:00', '12:00 - 12:30', '12:30 - 13:00', '13:00 - 13:15'];

  const handleClickOutside = (event: MouseEvent) => {
    if (overlayContentRef.current && !overlayContentRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Fehler: Fehlende Informationen</Popover.Header>
      <Popover.Body>
        Bitte wählen Sie zuerst eine Uhrzeit, danach kann die Bestellung abgeschlossen werden.
      </Popover.Body>
    </Popover>
  );

  return (
    <div className={styles.overlay}>
      <div className={styles.overlayContent} id="overlayContent" ref={overlayContentRef}>
        <div className={styles.topClose}>
          {success ? <h5>{'Du hast folgenden Gericht bestellt'}</h5> : <div></div>}
          <CloseButton onClick={onClose} color={COLORS.black} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <img src={imageUrl} alt={mealName} className={styles.mealImage} />
          <h5 className={styles.mealName}>{mealName}</h5>
        </div>
        <div className={styles.options}>
          {success ? (
            <div style={{ marginTop: 16 }}>
              <h6 style={{ fontWeight: 'bold' }}>{`${mealDate} | ${selectedTime}`}</h6>
            </div>
          ) : (
            <div className={styles.times}>
              <h5>
                <strong>{`${t(KEYS.timeOfDay)}:`}</strong>
              </h5>
              <div className={styles.timeSelect}>
                <DropdownComponent
                  items={reservationTime}
                  initialPlaceholder={t(KEYS.timeOfDay)}
                  onItemSelected={(_selectedTime) => setSelectedTime(_selectedTime)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
