import { Route, Routes, useNavigate } from 'react-router-dom';
import { CanteenPlan } from '@screens/Home/CanteenPlan';
import { ProfileScreen } from './screens/Profile/ProfileScreen';
import React, { useContext } from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import { Header } from './components/Header/Header';

export const AppRouter = () => {
  const { token, error, login } = useContext<IAuthContext>(AuthContext);
  const navigate = useNavigate();
  if (error) {
    console.log(error);
    login();
  }
  return (
    <>
      {!error && token && (
        <>
          <Header />
          <Routes>
            <Route path="/callback" element={<CanteenPlan />} />
            <Route path="/home" element={<CanteenPlan />} />
            <Route path="/profile" element={<ProfileScreen />} />
            <Route path="/" element={<CanteenPlan />} />
            <Route path="*" element={<CanteenPlan />} />
          </Routes>
        </>
      )}
    </>
  );
};
