import { DayToStringMapping } from '@services/helpers/dateHelper';
import React from 'react';
import './WeekDayLabel.style.scss';
import { useTranslation } from 'react-i18next';

export const WeekDayLabel = ({ day }: { day: number }) => {
  const { t } = useTranslation();

  return (
    <div className={'container'}>
      <span className={'label'}>{`${t(DayToStringMapping[day - 1])}`}</span>
    </div>
  );
};
