// Android uses file name
const FONT_FAMILY = {
  bold: 'ClanPro-Bold',
  news: 'ClanPro-News',
  medium: 'ClanPro-Medium',
  narrBlack: 'ClanPro-NarrBlack',
} as const;
export const FONTSIZE = {
  headline2600: 54,
  headline2000: 40,
  headline1400: 36,
  headline1100: 30,
  headline1000: 28,
  subheading800: 24,
  subheading700: 22,
  subheading600: 20,
  subheading500: 18,
  paragraph400: 16,
  paragraph300: 14,
  caption200: 12,
  caption150: 11,
  caption100: 10,
} as const;

export const FONT_LINE_HEIGHT = {
  headline2600: 68,
  headline2000: 50,
  headline1400: 46,
  headline1100: 38,
  headline1000: 36,
  subheading800: 32,
  subheading700: 28,
  subheading600: 26,
  subheading500: 24,
  paragraph400: 20,
  paragraph300: 18,
  caption200: 16,
  caption150: 14,
  caption100: 12,
} as const;

export const FONTS = {
  bold: {
    headline2600: {
      fontFamily: FONT_FAMILY.bold,
      fontSize: FONTSIZE.headline2600,
      lineHeight: FONT_LINE_HEIGHT.headline2600,
    },
    headline2000: {
      fontFamily: FONT_FAMILY.bold,
      fontSize: FONTSIZE.headline2000,
      lineHeight: FONT_LINE_HEIGHT.headline2000,
    },
    headline1400: {
      fontFamily: FONT_FAMILY.bold,
      fontSize: FONTSIZE.headline1400,
      lineHeight: FONT_LINE_HEIGHT.headline1400,
    },
    headline1100: {
      fontFamily: FONT_FAMILY.bold,
      fontSize: FONTSIZE.headline1100,
      lineHeight: FONT_LINE_HEIGHT.headline1100,
    },
    subheading800: {
      fontFamily: FONT_FAMILY.bold,
      fontSize: FONTSIZE.subheading800,
      lineHeight: FONT_LINE_HEIGHT.subheading800,
    },
    subheading700: {
      fontFamily: FONT_FAMILY.bold,
      fontSize: FONTSIZE.subheading700,
      lineHeight: FONT_LINE_HEIGHT.subheading700,
    },
    subheading600: {
      fontFamily: FONT_FAMILY.bold,
      fontSize: FONTSIZE.subheading600,
      lineHeight: FONT_LINE_HEIGHT.subheading600,
    },
    subheading500: {
      fontFamily: FONT_FAMILY.bold,
      fontSize: FONTSIZE.subheading500,
      lineHeight: FONT_LINE_HEIGHT.subheading500,
    },
    paragraph400: {
      fontFamily: FONT_FAMILY.bold,
      fontSize: FONTSIZE.paragraph400,
      lineHeight: FONT_LINE_HEIGHT.paragraph400,
    },
    paragraph300: {
      fontFamily: FONT_FAMILY.bold,
      fontSize: FONTSIZE.paragraph300,
      lineHeight: FONT_LINE_HEIGHT.paragraph300,
    },
    caption200: {
      fontFamily: FONT_FAMILY.bold,
      fontSize: FONTSIZE.caption200,
      lineHeight: FONT_LINE_HEIGHT.caption200,
    },
    caption150: {
      fontFamily: FONT_FAMILY.bold,
      fontSize: FONTSIZE.caption150,
      lineHeight: FONT_LINE_HEIGHT.caption150,
    },
    caption100: {
      fontFamily: FONT_FAMILY.bold,
      fontSize: FONTSIZE.caption100,
      lineHeight: FONT_LINE_HEIGHT.caption100,
    },
  },
  news: {
    headline2600: {
      fontFamily: FONT_FAMILY.news,
      fontSize: FONTSIZE.headline2600,
      lineHeight: FONT_LINE_HEIGHT.headline2600,
    },
    headline2000: {
      fontFamily: FONT_FAMILY.news,
      fontSize: FONTSIZE.headline2000,
      lineHeight: FONT_LINE_HEIGHT.headline2000,
    },
    headline1400: {
      fontFamily: FONT_FAMILY.news,
      fontSize: FONTSIZE.headline1400,
      lineHeight: FONT_LINE_HEIGHT.headline1400,
    },
    headline1100: {
      fontFamily: FONT_FAMILY.news,
      fontSize: FONTSIZE.headline1100,
      lineHeight: FONT_LINE_HEIGHT.headline1100,
    },
    subheading800: {
      fontFamily: FONT_FAMILY.news,
      fontSize: FONTSIZE.subheading800,
      lineHeight: FONT_LINE_HEIGHT.subheading800,
    },
    subheading700: {
      fontFamily: FONT_FAMILY.news,
      fontSize: FONTSIZE.subheading700,
      lineHeight: FONT_LINE_HEIGHT.subheading700,
    },
    subheading600: {
      fontFamily: FONT_FAMILY.news,
      fontSize: FONTSIZE.subheading600,
      lineHeight: FONT_LINE_HEIGHT.subheading600,
    },
    subheading500: {
      fontFamily: FONT_FAMILY.news,
      fontSize: FONTSIZE.subheading500,
      lineHeight: FONT_LINE_HEIGHT.subheading500,
    },
    paragraph400: {
      fontFamily: FONT_FAMILY.news,
      fontSize: FONTSIZE.paragraph400,
      lineHeight: FONT_LINE_HEIGHT.paragraph400,
    },
    paragraph300: {
      fontFamily: FONT_FAMILY.news,
      fontSize: FONTSIZE.paragraph300,
      lineHeight: FONT_LINE_HEIGHT.paragraph300,
    },
    caption200: {
      fontFamily: FONT_FAMILY.news,
      fontSize: FONTSIZE.caption200,
      lineHeight: FONT_LINE_HEIGHT.caption200,
    },
    caption150: {
      fontFamily: FONT_FAMILY.news,
      fontSize: FONTSIZE.caption150,
      lineHeight: FONT_LINE_HEIGHT.caption150,
    },
    caption100: {
      fontFamily: FONT_FAMILY.news,
      fontSize: FONTSIZE.caption100,
      lineHeight: FONT_LINE_HEIGHT.caption100,
    },
  },
  medium: {
    headline2600: {
      fontFamily: FONT_FAMILY.medium,
      fontSize: FONTSIZE.headline2600,
      lineHeight: FONT_LINE_HEIGHT.headline2600,
    },
    headline2000: {
      fontFamily: FONT_FAMILY.medium,
      fontSize: FONTSIZE.headline2000,
      lineHeight: FONT_LINE_HEIGHT.headline2000,
    },
    headline1400: {
      fontFamily: FONT_FAMILY.medium,
      fontSize: FONTSIZE.headline1400,
      lineHeight: FONT_LINE_HEIGHT.headline1400,
    },
    headline1100: {
      fontFamily: FONT_FAMILY.medium,
      fontSize: FONTSIZE.headline1100,
      lineHeight: FONT_LINE_HEIGHT.headline1100,
    },
    subheading800: {
      fontFamily: FONT_FAMILY.medium,
      fontSize: FONTSIZE.subheading800,
      lineHeight: FONT_LINE_HEIGHT.subheading800,
    },
    subheading700: {
      fontFamily: FONT_FAMILY.medium,
      fontSize: FONTSIZE.subheading700,
      lineHeight: FONT_LINE_HEIGHT.subheading700,
    },
    subheading600: {
      fontFamily: FONT_FAMILY.medium,
      fontSize: FONTSIZE.subheading600,
      lineHeight: FONT_LINE_HEIGHT.subheading600,
    },
    subheading500: {
      fontFamily: FONT_FAMILY.medium,
      fontSize: FONTSIZE.subheading500,
      lineHeight: FONT_LINE_HEIGHT.subheading500,
    },
    paragraph400: {
      fontFamily: FONT_FAMILY.medium,
      fontSize: FONTSIZE.paragraph400,
      lineHeight: FONT_LINE_HEIGHT.paragraph400,
    },
    paragraph300: {
      fontFamily: FONT_FAMILY.medium,
      fontSize: FONTSIZE.paragraph300,
      lineHeight: FONT_LINE_HEIGHT.paragraph300,
    },
    caption200: {
      fontFamily: FONT_FAMILY.medium,
      fontSize: FONTSIZE.caption200,
      lineHeight: FONT_LINE_HEIGHT.caption200,
    },
    caption150: {
      fontFamily: FONT_FAMILY.medium,
      fontSize: FONTSIZE.caption150,
      lineHeight: FONT_LINE_HEIGHT.caption150,
    },
    caption100: {
      fontFamily: FONT_FAMILY.medium,
      fontSize: FONTSIZE.caption100,
      lineHeight: FONT_LINE_HEIGHT.caption100,
    },
  },
  narrowBlack: {
    headline2600: {
      fontFamily: FONT_FAMILY.narrBlack,
      fontSize: FONTSIZE.headline2600,
      lineHeight: FONT_LINE_HEIGHT.headline2600,
    },
    headline2000: {
      fontFamily: FONT_FAMILY.narrBlack,
      fontSize: FONTSIZE.headline2000,
      lineHeight: FONT_LINE_HEIGHT.headline2000,
    },
    headline1400: {
      fontFamily: FONT_FAMILY.narrBlack,
      fontSize: FONTSIZE.headline1400,
      lineHeight: FONT_LINE_HEIGHT.headline1400,
    },
    headline1100: {
      fontFamily: FONT_FAMILY.narrBlack,
      fontSize: FONTSIZE.headline1100,
      lineHeight: FONT_LINE_HEIGHT.headline1100,
    },
    headline1000: {
      fontFamily: FONT_FAMILY.narrBlack,
      fontSize: FONTSIZE.headline1000,
      lineHeight: FONT_LINE_HEIGHT.headline1000,
    },
    subheading800: {
      fontFamily: FONT_FAMILY.narrBlack,
      fontSize: FONTSIZE.subheading800,
      lineHeight: FONT_LINE_HEIGHT.subheading800,
    },
    subheading700: {
      fontFamily: FONT_FAMILY.narrBlack,
      fontSize: FONTSIZE.subheading700,
      lineHeight: FONT_LINE_HEIGHT.subheading700,
    },
    subheading600: {
      fontFamily: FONT_FAMILY.narrBlack,
      fontSize: FONTSIZE.subheading600,
      lineHeight: FONT_LINE_HEIGHT.subheading600,
    },
    subheading500: {
      fontFamily: FONT_FAMILY.narrBlack,
      fontSize: FONTSIZE.subheading500,
      lineHeight: FONT_LINE_HEIGHT.subheading500,
    },
    paragraph400: {
      fontFamily: FONT_FAMILY.narrBlack,
      fontSize: FONTSIZE.paragraph400,
      lineHeight: FONT_LINE_HEIGHT.paragraph400,
    },
    paragraph300: {
      fontFamily: FONT_FAMILY.narrBlack,
      fontSize: FONTSIZE.paragraph300,
      lineHeight: FONT_LINE_HEIGHT.paragraph300,
    },
    caption200: {
      fontFamily: FONT_FAMILY.narrBlack,
      fontSize: FONTSIZE.caption200,
      lineHeight: FONT_LINE_HEIGHT.caption200,
    },
    caption150: {
      fontFamily: FONT_FAMILY.narrBlack,
      fontSize: FONTSIZE.caption150,
      lineHeight: FONT_LINE_HEIGHT.caption150,
    },
    caption100: {
      fontFamily: FONT_FAMILY.narrBlack,
      fontSize: FONTSIZE.caption100,
      lineHeight: FONT_LINE_HEIGHT.caption100,
    },
  },
} as const;
