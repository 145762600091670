import axios, { AxiosInstance } from 'axios';
import { useContext, useEffect } from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_ENDPOINT}`,
});

export const useClient = (): AxiosInstance => {
  const { token } = useContext<IAuthContext>(AuthContext);

  useEffect(() => {
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }, [token]);

  return axiosInstance;
};
