import React from 'react';
import { ReservationList } from '@components/PersonalSpace/ReservationList/ReservationList';
import { CollectedPoints } from '@components/PersonalSpace/CollectedPoints/CollectedPoints';
import './PersonalSpace.style.scss';

export const PersonalSpace = (): React.JSX.Element => {
  return (
    <div className={'personalSpaceContainer'}>
      <CollectedPoints />
      <ReservationList />
    </div>
  );
};
