import { useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

import { AppRouter } from './AppRouter';
import { DataProvider } from './providers/data/DataProvider';

import './App.css';
import './assets/css/global.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/scss/variables.scss';

function App() {
  const { token, loginInProgress } = useContext<IAuthContext>(AuthContext);

  if (!token || loginInProgress) {
    return null;
  }

  return (
    <DataProvider>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </DataProvider>
  );
}

export default App;
