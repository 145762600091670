import { mealTileStyle } from 'src/components/MealTile/MealTile.style';
import React from 'react';
import { Meal, MealIngredient } from '@type/Meal';

export const Ingredients = ({ meal }: { meal: Meal }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {meal?.ingredients?.map((ingredient: MealIngredient) => {
        return (
          <div key={ingredient?.id}>
            {ingredient?.iconUrl ? <img src={ingredient?.iconUrl} style={mealTileStyle.mealIngredient} /> : null}
          </div>
        );
      })}
    </div>
  );
};
